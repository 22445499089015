<template>
  <b-card no-body>
    <validation-observer
      v-slot="{ handleSubmit }" ref="VForm"
    >
      <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="8">
              <b-row>
                <b-col lg="6">
                  <b-form-group>
                    <label>Nama<span class="text-danger mr5">*</span></label>
                    <b-form-input type="text" class="form-control" v-model="row.mtk_name" placeholder="e.g Js. Sarana Tetap" />
                    <VValidate 
                      name="Nama" 
                      v-model="row.mtk_name" 
                      :rules="toValidate(mrValidation.mtk_name)"
                    />
                  </b-form-group>
                </b-col>
                <div class="w-100"></div>
                <b-col lg="6">
                  <b-form-group class="mt-3">
                    <label>Status <span class="text-danger mr5">*</span></label>
                    <b-form-radio-group
                      :options="Config.mr.StatusOptions"
                      v-model="row[$parent.statusKey]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer>
          <b-row>
            <b-col lg="12" class="text-right">
              <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
              <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-form>
    </validation-observer>
  </b-card>
</template>
  
<script>
  const _ = global._
  
  export default{
    props:{
      row:Object,
      mrValidation:Object,
      Config:Object
    },
    methods: {
      toValidate(val){
        return {...val}
      }
    },
  }
  </script>